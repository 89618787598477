
import AnimatedWord from "./AnimatedWord";

export default function AnimatedHeader(props) {
	const headerType = props.headerType || "h3";
	const lines = props.lines || [];
	let index = 0;

	let headerContents = (
				lines.map((line) => {
					return (
							<div key={index++}
									 className={"line"}>
								{
									line.map((word) => {
										return (
												<div key={index++}
														 className={"word"}>
													<AnimatedWord word={word.word}
																				className={word.className} />{word.space ? " " : ""}</div>
										);
									})
								}
							</div>
					);
				})
	);

	return (
			<header>
				{
					headerType === "h1"
							? (<h1>{headerContents}</h1>)
							: headerType === "h2"
									? <h2>{headerContents}</h2>
									: <h3>{headerContents}</h3>
				}
			</header>
	)
}
