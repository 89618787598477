
const TetrisBlockSVGLeftT = () => {
	return (
			<svg id="tetris-block-left-t"
					 width="152" height="222" viewBox="0 0 152 222" fill="none" xmlns="http://www.w3.org/2000/svg">
				{/* Innermost tile of tetris block */}
				<path d="M71 71H151V151H71V71Z" fill="black" />
				<path d="M81 81H141V141H81V81Z" fill="#E44F9C" className={"inner-tetris-shadow"} />
				<path d="M91 81H141V131H91V81Z" fill="#FF69B4" className={"inner-tetris-tile"} />
				<path d="M1 141H81V221H1V141Z" fill="black" className={"fade-block"} />
				<path d="M11 151H71V211H11V151Z" fill="#E44F9C" className={"fade-block"} />
				<path d="M21 151H71V201H21V151Z" fill="#FF69B4" className={"fade-block"} />
				<path d="M1 71H81V151H1V71Z" fill="black" className={"fade-block"} />
				<path d="M11 81H71V141H11V81Z" fill="#E44F9C" className={"fade-block"} />
				<path d="M21 81H71V131H21V81Z" fill="#FF69B4" className={"fade-block"} />
				<path d="M1 1H81V81H1V1Z" fill="black" className={"fade-block"} />
				<path d="M11 11H71V71H11V11Z" fill="#E44F9C" className={"fade-block"} />
				<path d="M21 11H71V61H21V11Z" fill="#FF69B4" className={"fade-block"} />
				{/*<path d="M151.5 71V70.5H151H81.5V1V0.5H81H1H0.5V1V70.5V71V81V81.5V140.5V141V151V151.5V221V221.5H1H81H81.5V221V151.5H151H151.5V151V71Z" stroke="black" />*/}
			</svg>
	);
}

export default TetrisBlockSVGLeftT;
