
import Section from "../../components/Section";
import AnimatedHeader from "../../components/animations/AnimatedHeader";
import {attachLetterAnimations} from "../../utilities/DelayedAnimations";
import {useEffect} from "react";
import {
	getFadeInVariants,
	getSlideRightFadeInVariants,
	getSlideUpFadeInVariants
} from "../../utilities/AnimationVariants";
import {Flip, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {EmailJsForm} from "./EmailJSForm";

const headerLines = [
	[
		{ word: "Contact Me", className: "letter title-gradient", space: true }
	]
];

const ContactTextForm = (props) => {
	return (
			<div className={"padded-left-area"}>
				{
					props.isH1 ?
							<AnimatedHeader headerType={"h1"} lines={headerLines} /> :
							<AnimatedHeader headerType={"h2"} lines={headerLines} />
				}
				<p className={"text-section"}>
					<span className={"code"}>&gt;&gt;  </span>
					Have any questions or requests? Feel free to drop a message and I'll get back to you.
				</p>
				<div className={"reverse-box-background"} style={{marginTop: "1rem"}}>
					<EmailJsForm />
				</div>
			</div>
	)
}

const ContactMap = () => {
	return (
			<img src={"https://i.imgur.com/7KAEjLO.jpg"}
					 alt={"An approximate GPS location of my current residence."}
					 className={"contact-map"} />
	)
}

const Contact = (props) => {
	useEffect(() => {
		attachLetterAnimations().catch(exception => console.log(exception));
	});

	return (
			<>
			<Section sectionClass={"grid-split" + (props.isOwnPage ? " individual-page" : " vertical-margin")}
							 elements={[
									 <ContactTextForm isH1={props.isOwnPage} />,
								 <ContactMap />
							 ]}
							 sectionVariants={getFadeInVariants()}
							 itemVariants={props.isOwnPage ? getSlideUpFadeInVariants() : getSlideRightFadeInVariants()} />
				<ToastContainer transition={Flip}
												closeButton={false}
												closeOnClick
												pauseOnHover
												draggable />
			</>
	);
}

export default Contact;
