
import * as React from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./MenuItem";
import {NavLinks} from "./NavLinks";

const variants = {
	open: {
		transition: { staggerChildren: 0.05, delayChildren: 0.1 }
	},
	closed: {
		transition: { staggerChildren: 0.03, staggerDirection: -1 }
	}
};

export const Navigation = ({ isOpen, currentPath, toggleEvent }) => {
	let index = 0;

	return (
			<motion.ul variants={variants}>
				<div style={{
					position: "fixed",
					top: "calc((100vh - (var(--nav-link-size) * 6)) / 2)",
					pointerEvents: (isOpen ? "auto" : "none")
				}}>
				{
					NavLinks({ currentPath, toggleEvent }).map((element) => (
							<MenuItem key={index++}
												element={element}/>
					))
				}
				</div>
			</motion.ul>
	);
};
