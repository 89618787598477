
import Section from "../../components/Section";
import AnimatedHeader from "../../components/animations/AnimatedHeader";
import {attachLetterAnimations} from "../../utilities/DelayedAnimations";
import {useEffect} from "react";
import {
	getFadeInVariants,
	getSlideRightFadeInVariants,
	getSlideUpFadeInVariants
} from "../../utilities/AnimationVariants";
import {portfolioData} from "../../data/PortfolioData";

const headerLines = [
	[
		{ word: "My Portfolio", className: "letter title-gradient", space: true }
	]
];

function ProjectsData() {
	const projectTiles = [];
	let index = 0;

	portfolioData.map((project) => {
		return (projectTiles.push(
				<div key={index++}
						 className={"project-tile reverse-box-background"}>
					<h3>{project.title}</h3>
					<div className={"tile-tech-stack"}>
						{
							project.techStack.sort().reverse().map((techStack) => {
								return (
										<p key={index++}>
											{techStack}
										</p>
								);
							})
						}
					</div>
					<a href={project.accessLink}
						 target={(project.accessLink.length > 1) ? "_blank" : ""}
						 rel={"noreferrer"}
						 className={`${(project.accessLink.length > 1) ? "" : "disabled-link"} glow-effect`}
						 data-glow-full-width={true}
						 data-glow-offset={true}
						 data-glow-project-tile={true}>
						<img src={project.imgSrc}
								 alt={project.imgAlt} />
						<span className={(project.accessLink.length > 1) ? "" : "disabled"}>
							{
								(project.accessLink.length > 1) ?
										"VIEW PROJECT" :
										"COMING SOON ..."
							}
						</span>
						<svg className={"glow-container"}>
							<rect pathLength={100} strokeLinecap={"round"} className={"glow-line"}></rect>
							<rect pathLength={100} strokeLinecap={"round"} className={"glow-blur"}></rect>
						</svg>
					</a>
				</div>
		));
	})

	return (
			<div id={"portfolio-section"}>
				{projectTiles}
			</div>
	);
}

function ProjectsSection(props) {
	return (
			<div>
				{
					props.isH1 ?
							<AnimatedHeader headerType={"h1"} lines={headerLines} /> :
							<AnimatedHeader headerType={"h2"} lines={headerLines} />
				}
				<ProjectsData />
			</div>
	);
}

const Portfolio = (props) => {
	useEffect(() => {
		attachLetterAnimations().catch(exception => console.log(exception));
	});

	return (
			<Section sectionClass={"grid-split" + (props.isOwnPage ? " individual-page" : " vertical-margin")}
							 elements={[
									 <ProjectsSection isH1={props.isOwnPage} />
							 ]}
							 sectionVariants={getFadeInVariants()}
							 itemVariants={props.isOwnPage ? getSlideUpFadeInVariants() : getSlideRightFadeInVariants()} />
	);
}

export default Portfolio;
