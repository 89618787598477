
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faMoon, faSun }
	from "@fortawesome/free-solid-svg-icons";

export default function SwitchTheme({className, style}) {
	const [mode, setMode] = useState('dark');

	useEffect(() => {
		const selectedTheme = localStorage.getItem("selected-theme");
		if (selectedTheme === "light") {
			setMode("light");
		} else {
			setMode("dark");
		}
	}, []);

	const onSelectMode = (mode) => {
		setMode(mode);

		if (mode === "light") {
			localStorage.setItem("selected-theme", "light");
			document.body.classList.remove("dark-mode");
			document.body.classList.add("light-mode");
		} else {
			localStorage.setItem("selected-theme", "dark");
			document.body.classList.remove("light-mode");
			document.body.classList.add("dark-mode");
		}
	}

	return (
					mode === 'dark' ?
							<div className={`nav-link ${className}`}
									 style={style}
									 onClick={() => onSelectMode('light')}>
								<FontAwesomeIcon icon={faMoon}
																 size={"2x"}
																 className={"theme-icon layer-1"}  />
								<span className={"layer-2"}>
									SWITCH THEME
								</span>
							</div> :
							<div className={`nav-link ${className}`}
									 style={style}
									 onClick={() => onSelectMode('dark')}>
								<FontAwesomeIcon icon={faSun}
																 size={"2x"}
																 className={"theme-icon layer-1"}  />
								<span className={"layer-2"}>
									SWITCH THEME
								</span>
							</div>
	);
}
