
export function getTitleBounceAnimation(delayDuration) {
	return ({
		filter: [
			"hue-rotate(0)",
			"hue-rotate(36deg)",
			"hue-rotate(108deg)",
			"hue-rotate(180deg)",
			"hue-rotate(213deg)",
			"hue-rotate(244deg)",
			"hue-rotate(360deg)",
			"hue-rotate(0deg)"
		],
		opacity: [0, 0.5, 1, 1, 1, 1, 1, 1],
		scaleX: [1, 1.1, 0.9, 1.05, 1, 1, 1, 1],
		scaleY: [1, 0.8, 1.1, 0.95, 1, 1, 1, 1],
		translateY: [0, -10, 0, -5, 0, 0],
		transition: {times: [0, 0.1, 0.3, 0.5, 0.59, 0.69, 1, 1],
			delay: delayDuration, duration: 1, ease: "easeInOut"}
	});
}

const visible = { opacity: 1, x: 0, y: 0, transition: { delay: 0.3, duration: 0.4, staggerChildren: 0.2 } };

export function getFadeInVariants() {
	return ({
		visible: { transition: { staggerChildren: 0.2 } }
	});
}


export function getSlideRightFadeInVariants() {
	return ({
		hidden: { opacity: 0, x: -25 },
		visible
	});
}

export function getSlideUpFadeInVariants() {
	return ({
		hidden: { opacity: 0, y: 10 },
		visible
	});
}

export function getSlideDownFadeOutVariants() {
	return ({
		opacity: 0, y: 10, transition: { duration: 0.3, ease: "easeOut" }
	});
}
