
import {delay} from "./HelperFunctions";

export async function attachLetterAnimations() {
	await delay(1250);
	let letters = document.getElementsByClassName("letter");

	for (let i = 0; i < letters.length; i++) {
		letters[i].addEventListener("animationend", function() {
			letters[i].classList.remove("animated-letter");
		});

		letters[i].addEventListener("mouseover", function() {
			letters[i].classList.add("animated-letter");
		})
	}
}

export async function attachLogoAnimations() {
	await delay(3000);
	let tetrisBlocks = document.getElementsByClassName("tetris-block");

	for (let i = 0; i < tetrisBlocks.length; i++) {
		tetrisBlocks[i].addEventListener("animationend", function() {
			tetrisBlocks[i].classList.remove("animated-tetris-block");
		});

		tetrisBlocks[i].addEventListener("mouseover", function() {
			tetrisBlocks[i].classList.add("animated-tetris-block");
		})
	}
}

export async function attachProfileMouseListener() {
	await delay(1500);
	const logo = document.getElementById("profile-photo-wrapper");
	if (logo === null) {
		return;
	}
	const section = document.querySelector("section");

	section.addEventListener("mousemove", (event) => {
		rotateElement(event, logo);
	})

	function rotateElement(event, element) {
		// Get the mouse position
		const x = event.clientX;
		const y = event.clientY;

		// Find the middle
		const middleX = window.innerWidth / 2;
		const middleY = window.innerHeight / 2;

		// Get the offset from the middle (in degrees)
		const offsetX = ((x - middleX) / middleX) * 45;
		const offsetY = ((y - middleY) / middleY) * 45;

		element.style.setProperty("--rotateX", (-1 * offsetY * 0.75) + "deg");
		element.style.setProperty("--rotateY", (offsetX * 0.75) + "deg");
	}
}

export async function attachMemojiAnimations() {
	let memojis = document.getElementsByClassName("memoji");

	for (let i = 0; i < memojis.length; i++) {
		memojis[i].addEventListener("animationend", function() {
			memojis[i].classList.remove("animated-memoji");
		});

		memojis[i].addEventListener("mouseover", function() {
			memojis[i].classList.add("animated-memoji");
		})
	}
}

export async function attachAllAnimations() {
	await attachProfileMouseListener();
	await attachMemojiAnimations();
	await attachLetterAnimations();
	await attachLogoAnimations();
}
