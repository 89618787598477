
export default function TetrisBlockSVGRightT() {
	return (
			<svg id="tetris-block-right-t"
					 width="152" height="222" viewBox="0 0 152 222" fill="none" xmlns="http://www.w3.org/2000/svg">
				{/* Innermost tile of tetris block */}
				<path d="M1 71H81V151H1V71Z" fill="black" />
				<path d="M11 81H71V141H11V81Z" fill="#E44F9C" className={"inner-tetris-shadow"} />
				<path d="M21 81H71V131H21V81Z" fill="#FF69B4" className={"inner-tetris-tile"} />
				<path d="M71 141H151V221H71V141Z" fill="black" className={"fade-block"} />
				<path d="M81 151H141V211H81V151Z" fill="#E44F9C" className={"fade-block"} />
				<path d="M91 151H141V201H91V151Z" fill="#FF69B4" className={"fade-block"} />
				<path d="M71 71H151V151H71V71Z" fill="black" className={"fade-block"} />
				<path d="M81 81H141V141H81V81Z" fill="#E44F9C" className={"fade-block"} />
				<path d="M91 81H141V131H91V81Z" fill="#FF69B4" className={"fade-block"} />
				<path d="M71 1H151V81H71V1Z" fill="black" className={"fade-block"} />
				<path d="M81 11H141V71H81V11Z" fill="#E44F9C" className={"fade-block"} />
				<path d="M91 11H141V61H91V11Z" fill="#FF69B4" className={"fade-block"} />
				{/*<path d="M1 70.5H0.5V71V151V151.5H1H70.5V221V221.5H71H151H151.5V221V151.5V151V141V140.5V81.5V81V71V70.5V1V0.5H151H71H70.5V1V70.5H1Z" stroke="black" />*/}
			</svg>
	);
}
