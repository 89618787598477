
import './App.css';
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import Navbar from "./navigation/Navbar";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Skills from "./pages/skills/Skills";
import Portfolio from "./pages/projects/Portfolio";
import Contact from "./pages/contact/Contact";
import {AnimatePresence} from "framer-motion";
import React from "react";

function App() {
	const location = useLocation();

	return (
			<>
				<Navbar />
				<main>
					<AnimatePresence mode={"wait"}>
						<Routes location={location} key={location.key}>
							<Route path={"/"} element={<Home />} />
							<Route path={"/about"} element={<About isOwnPage={true} />} />
							<Route path={"/skills"} element={<Skills isOwnPage={true} />} />
							<Route path={"/portfolio"} element={<Portfolio isOwnPage={true} />} />
							<Route path={"/contact"} element={<Contact isOwnPage={true} />} />
							<Route path={"*"} element={<Navigate to={"/"} replace />} />
						</Routes>
					</AnimatePresence>
				</main>
			</>
	);
}

export default App;
