
import {useRef, useState} from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';

export const EmailJsForm = () => {
	const [formState, setFormState] = useState({
		email: "",
		emailState: "default",
		name: "",
		subject: "",
		message: "",
		messageState: "default"
	});
	const form = useRef();

	const handleEmailChange = (event) => {
		const input = event.target.value;

		setFormState({
			...formState,
			email: input,
			emailState: (matchesEmailPattern(input) ? "valid" : "invalid")
		});
	};

	const handleNameChange = (event) => {
		const input = event.target.value;

		setFormState({
			...formState,
			name: input
		});
	};

	const handleSubjectChange = (event) => {
		const input = event.target.value;

		setFormState({
			...formState,
			subject: input
		});
	};

	const handleMessageChange = (event) => {
		const input = event.target.value;

		setFormState({
			...formState,
			message: input,
			messageState: ((input.length > 0) ? "valid" : "invalid")
		});
	};

	function allFieldsAreValid() {
		return (formState.emailState === "valid" && formState.messageState === "valid");
	}

	function validateForm() {
		// Base case - we're done if all required fields of the form are valid
		if (allFieldsAreValid()) {
			return true;
		}

		let currentEmailState = (formState.emailState === "valid" ? "valid" : "invalid");
		let currentMessageState = (formState.messageState === "valid" ? "valid" : "invalid");

		setFormState({
			...formState,
			emailState: currentEmailState,
			messageState: currentMessageState
		})

		toast.error("Please fill in the required fields.", {
			toastId: "invalid-form",
			autoClose: 10000
		});

		let requiredFields = document.getElementsByClassName("required-field");
		for (let i = 0; i < requiredFields.length; i++) {
			if (requiredFields[i].classList.contains("invalid-field")) {
				requiredFields[i].classList.add("shake");

				requiredFields[i].addEventListener("animationend", function() {
					requiredFields[i].classList.remove("shake");
				});
			}
		}

		return false;
	}

	function matchesEmailPattern(input) {
		return (input.match("[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"));
	}

	const sendEmail = async (event) => {
		event.preventDefault();

		const isValidForm = validateForm();
		if (!isValidForm) {
			return;
		}

		let promise = emailjs.sendForm(
				process.env.REACT_APP_EMAIL_SERVICE_ID,
				process.env.REACT_APP_EMAIL_TEMPLATE_ID,
				form.current,
				process.env.REACT_APP_EMAIL_PUBLIC_KEY)
				.then((result) => {
					if (result.text === "OK") {
						setFormState({
							email: "",
							emailState: "default",
							name: "",
							subject: "",
							message: "",
							messageState: "default"
						});
					} else {
						console.log(result.text);
					}
				}, (error) => {
					console.log(error.text);
				});

		toast.dismiss();
		await toast.promise(promise, {
			pending: "Sending message ...",
			success: "Message sent!",
			error: "An error occurred; try again later.",
		});
	};

	function onEnterPress(event) {
		if(event.keyCode === 13 && event.shiftKey === false) {
			sendEmail(event).catch(error => console.log(error));
		}
	}

	return (
			<>
				<form className={"grid-form"}
							ref={form}
							onSubmit={ sendEmail }>
					<input type={"text"}
								 className={"span-1 required-field" +
										 (formState.emailState === "invalid" ? " invalid-field" : "")}
								 name={"email"}
								 placeholder={"Email *"}
								 value={formState.email}
								 onChange={ handleEmailChange } />
					<input type={"text"}
								 className={"span-1"}
								 name={"name"}
								 placeholder={"Name"}
								 value={formState.name}
								 onChange={ handleNameChange }/>
					<input type={"text"}
								 className={"span-2"}
								 name={"subject"}
								 placeholder={"Subject"}
								 value={formState.subject}
								 onChange={ handleSubjectChange } />
					<textarea className={"span-2 set-text-box-height required-field" +
							(formState.messageState === "invalid" ? " invalid-field" : "")}
										name={"message"}
										placeholder={"Message *"}
										value={formState.message}
										onChange={ handleMessageChange }
										onKeyDown={ onEnterPress }/>
					<div className={"span-2 glow-effect"}
							 data-glow-full-width={"true"}>
							<input type={"submit"}
										 className={"button"}
										 style={{
											 fontSize: "1rem",
											 justifyContent: "center",
											 width: "100%",
											 padding: "1rem 2.5em",
											 margin: "0"}}
										 value={"Send message!"} />
							<svg className={"glow-container"}>
								<rect pathLength={100} strokeLinecap={"round"} className={"glow-line"}></rect>
								<rect pathLength={100} strokeLinecap={"round"} className={"glow-blur"}></rect>
							</svg>
					</div>
				</form>
			</>
	);
}
