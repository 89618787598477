
import Section from "../../components/Section";
import AnimatedHeader from "../../components/animations/AnimatedHeader";
import Memoji from "../../assets/MemojiSVG";
import {
	getFadeInVariants, getSlideRightFadeInVariants,
	getSlideUpFadeInVariants
} from "../../utilities/AnimationVariants";
import {Link} from "react-router-dom";

const headerLines = [
	[
		{ word: "About Me", className: "letter title-gradient", space: false }
	]
];

const AboutText = (props) => {
	return (
			<div className={"padded-left-area"}>
				{
					props.isH1 ?
						<AnimatedHeader headerType={"h1"} lines={headerLines} /> :
						<AnimatedHeader headerType={"h2"} lines={headerLines} />
				}
				<p className={"text-section"}>
					<span className={"code"}>&gt;&gt;  </span>
					I'm a Fullstack Web Developer and Software Engineer located in California, USA.
					<br /><br />
					<span className={"code"}>&gt;&gt;  </span>
					I have a serious passion for learning about the entire frontend spectrum with compatible backend
					technologies to create modern applications with dynamic user experience.
					<br /><br />
					<span className={"code"}>&gt;&gt;  </span>
					Dedicated, ambitious, highly attentive to detail, and a timely communicator able to
					operate in both independent and diverse team environments with a fervent love for long code grinds.
					<br /><br />
					<span className={"code"}>&gt;&gt;  </span>
					In my free time I play piano, practice archery, and enjoy watching current TV series, movies, and anime.
					<br /><br />
					<span className={"code"}>&gt;&gt;  </span>
					<Link to={"/contact"}
								className={"text-link"}>Let's keep in touch.</Link>
				</p>
			</div>
	)
}

const About = (props) => {
	return (
			<>
				<Section sectionClass={"grid-split" + (props.isOwnPage ? " individual-page" : " vertical-margin")}
								 elements={[
										 <AboutText isH1={props.isOwnPage} />,
									 <Memoji />
								 ]}
								 sectionVariants={getFadeInVariants()}
								 itemVariants={props.isOwnPage ? getSlideUpFadeInVariants() : getSlideRightFadeInVariants()} />
			</>
	);
}

export default About;
