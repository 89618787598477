
import * as React from "react";
import { motion } from "framer-motion";

const variants = {
	open: {
		y: 0,
		opacity: 1,
		transition: {
			y: { stiffness: 1000, velocity: -100 }
		}
	},
	closed: {
		y: 50,
		opacity: 0,
		transition: {
			y: { stiffness: 1000 }
		}
	}
};

export const MenuItem = ({ element }) => {
	return (
			<motion.li variants={variants}>
				{element}
			</motion.li>
	);
};
