
import {useEffect} from "react";
import Typewriter from 'typewriter-effect';
import Section from "../../components/Section";
import TetrisBlockSVGI from "../../assets/TetrisBlockSVGI";
import TetrisBlockSVGLeftT from "../../assets/TetrisBlockSVGLeftT";
import TetrisBlockSVGRightT from "../../assets/TetrisBlockSVGRightT";
import AnimatedBlock from "../../components/animations/AnimatedBlock";
import {attachAllAnimations} from "../../utilities/DelayedAnimations";
import AnimatedHeader from "../../components/animations/AnimatedHeader";
import {
	getFadeInVariants
} from "../../utilities/AnimationVariants";
import {Link} from "react-router-dom";

const headerLines = [
	[
		{ word: "Hello World!", className: "letter", space: false }
	],
	[
		{ word: "I'm", className: "letter", space: true },
		{ word: "Steven Dao.", className: "letter title-gradient", space: false },
	]
];

const IntroductionText = () => {
	useEffect(() => {
		attachAllAnimations().then();
	})

	return (
			<div className={"padded-left-area"}>
				<AnimatedHeader headerType={"h1"} lines={headerLines} />
				<div className={"job-title"}>
					<Typewriter
							onInit={(typewriter) => {
								typewriter.changeDelay(50)
										.pauseFor(500)
										.typeString("Fullstack web developer,")
										.pauseFor(250)
										.typeString("\nSoftware engineer.")
										.start();
							}} />
				</div>
				<Link to={"/about"}
							className={"button glow-effect"}
							data-glow-intro-button={true}>
					About
					<svg className={"glow-container"}>
						<rect pathLength={100} strokeLinecap={"round"} className={"glow-line"}></rect>
						<rect pathLength={100} strokeLinecap={"round"} className={"glow-blur"}></rect>
					</svg>
				</Link>
				<Link to={"/contact"}
							className={"button glow-effect"}
							data-glow-intro-button={true}>
					Contact me
					<svg className={"glow-container"}>
						<rect pathLength={100} strokeLinecap={"round"} className={"glow-line"}></rect>
						<rect pathLength={100} strokeLinecap={"round"} className={"glow-blur"}></rect>
					</svg>
				</Link>
			</div>
	);
}

const LogoSection = () => {
	return (
			<div id={"logo-wrapper"}>
				<div className={"content-slide delayed"}>
					<AnimatedBlock icon={<TetrisBlockSVGI />}
												 className={"tetris-block"} />
				</div>
				<div className={"content-slide delayed"}>
					<AnimatedBlock icon={<TetrisBlockSVGRightT />}
												 className={"tetris-block"} />
				</div>
				<div className={"content-slide delayed"}>
					<AnimatedBlock icon={<TetrisBlockSVGI />}
												 className={"tetris-block"} />
				</div>
				<div className={"content-slide delayed"}>
					<AnimatedBlock icon={<TetrisBlockSVGLeftT />}
												 className={"tetris-block"} />
				</div>
				<div className={"content-slide delayed"}>
					<AnimatedBlock icon={<TetrisBlockSVGI />}
												 className={"tetris-block"} />
				</div>
			</div>
	);
}

const Introduction = () => {
	useEffect(() => {
		attachAllAnimations().catch(exception => console.log(exception));
	})

	return (
			<Section sectionClass={"grid-split fill-viewport"}
							 elements={[
									 <IntroductionText />,
								 <LogoSection />
							 ]}
							 sectionVariants={getFadeInVariants()}
							 itemVariants={getFadeInVariants()} />
	);
}

export default Introduction;
