
import Introduction from "./Introduction";
import Portfolio from "../projects/Portfolio";
import Skills from "../skills/Skills";
import Contact from "../contact/Contact";

const Home = () => {
	return (
			<>
				<Introduction />
				<Portfolio isOwnPage={false} />
				<Skills isOwnPage={false} />
				<Contact isOwnPage={false} />
			</>
	);
}

export default Home;
