
import React from 'react';

export default function AnimatedBlock({ icon, className }) {

	return (
			<div className={className}>
				{icon}
			</div>
	);
}
