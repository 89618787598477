
import {motion, useAnimationControls} from "framer-motion";
import {useInView} from "react-intersection-observer";
import {useEffect} from "react";
import {attachAllAnimations} from "../utilities/DelayedAnimations";
import {getSlideDownFadeOutVariants} from "../utilities/AnimationVariants";

export default function Section(props) {
	const controls = useAnimationControls();
	const [ref, inView] = useInView({
		triggerOnce: true
	});

	const sectionClass = props.sectionClass || null;
	const itemClass = props.itemClass || null;
	const sectionVariants = props.sectionVariants || null;
	const itemVariants = props.itemVariants || null;
	const animation = props.animation || "visible";
	const elements = props.elements || [];
	let index = 0;

	useEffect(() => {
		if (inView) {
			attachAllAnimations().catch(exception => console.log(exception));
			controls.start(animation).catch(exception => console.log(exception));
		}
	});

	function SectionElements() {
		let sectionElements = [];

		elements.map((element) => {
			return (sectionElements.push(
					<motion.div key={index++}
											className={itemClass}
											initial={"hidden"}
											whileInView={"visible"}
											viewport={{ once: true }}
											variants={itemVariants}
											transition={{ duration: 0.3 }}>
						{element}
					</motion.div>
			));
		});

		return (<>{sectionElements}</>);
	}

	return (
			<motion.section key={index++}
											ref={ref}
											className={sectionClass}
											initial={"hidden"}
											whileInView={"visible"}
											variants={sectionVariants}
											transition={{ duration: 0.3 }}
											exit={getSlideDownFadeOutVariants()}>
				<SectionElements />
			</motion.section>
	);
}
