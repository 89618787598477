import {Link} from "react-router-dom";
import LogoSVG from "../assets/LogoSVG";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo, faCode, faEnvelope, faFolderTree} from "@fortawesome/free-solid-svg-icons";
import { faGithub, faLinkedinIn } from "@fortawesome/free-brands-svg-icons"
import * as React from "react";
import SwitchTheme from "../components/SwitchTheme";

const homeStyle = {
	position: "fixed",
	top: "0",
	left: "0",
}

const externalLinksStyle = {
	position: "fixed",
	left: "0",
	bottom: "var(--nav-link-size)"
}

const switchThemeStyle = {
	position: "fixed",
	left: "0",
	bottom: "0",
}

export const NavLinks = ( {currentPath, className, toggleEvent} ) => {
	const links = [
		<Link key={"/home"}
					to={"/"}
					onClick={toggleEvent}
					style={(className && homeStyle)}
					className={`nav-link glow-effect ${className}` + ( (currentPath === "/") ? " active" : "" )}
					data-glow-navbar-item={true}>
			<LogoSVG />
			<span className={"layer-2"}>
				HOME
			</span>
			<svg className={"glow-container"}>
				<rect pathLength={100} strokeLinecap={"round"} className={"glow-line"}></rect>
				<rect pathLength={100} strokeLinecap={"round"} className={"glow-blur"}></rect>
			</svg>
		</Link>,
		<Link key={"/portfolio"}
					to={"/portfolio"}
					onClick={toggleEvent}
					className={`nav-link glow-effect ${className}` + ( (currentPath === "/portfolio") ? " active" : "" )}
					data-glow-navbar-item={true}>
			<FontAwesomeIcon icon={faFolderTree}
											 size={"2x"}
											 className={"layer-1"} />
			<span className={"layer-2"}>
				PROJECTS
			</span>
			<svg className={"glow-container"}>
				<rect pathLength={100} strokeLinecap={"round"} className={"glow-line"}></rect>
				<rect pathLength={100} strokeLinecap={"round"} className={"glow-blur"}></rect>
			</svg>
		</Link>,
		<Link key={"/skills"}
					to={"/skills"}
					onClick={toggleEvent}
					className={`nav-link glow-effect ${className}` + ( (currentPath === "/skills") ? " active" : "" )}
					data-glow-navbar-item={true}>
			<FontAwesomeIcon icon={faCode}
											 size={"2x"}
											 className={"layer-1"} />
			<span className={"layer-2"}>
				SKILLS
			</span>
			<svg className={"glow-container"}>
				<rect pathLength={100} strokeLinecap={"round"} className={"glow-line"}></rect>
				<rect pathLength={100} strokeLinecap={"round"} className={"glow-blur"}></rect>
			</svg>
		</Link>,
		<Link key={"/about"}
					to={"/about"}
					onClick={toggleEvent}
					className={`nav-link glow-effect ${className}` + ( (currentPath === "/about") ? " active" : "" )}
					data-glow-navbar-item={true}>
			<FontAwesomeIcon icon={faCircleInfo}
											 size={"2x"}
											 className={"layer-1"} />
			<span className={"layer-2"}>
				ABOUT
			</span>
			<svg className={"glow-container"}>
				<rect pathLength={100} strokeLinecap={"round"} className={"glow-line"}></rect>
				<rect pathLength={100} strokeLinecap={"round"} className={"glow-blur"}></rect>
			</svg>
		</Link>,
		<Link key={"/contact"}
					to={"/contact"}
					onClick={toggleEvent}
					className={`nav-link glow-effect ${className}` + ( (currentPath === "/contact") ? " active" : "" )}
					data-glow-navbar-item={true}>
			<FontAwesomeIcon icon={faEnvelope}
											 size={"2x"}
											 className={"layer-1"} />
			<span className={"layer-2"}>
				CONTACT
			</span>
			<svg className={"glow-container"}>
				<rect pathLength={100} strokeLinecap={"round"} className={"glow-line"}></rect>
				<rect pathLength={100} strokeLinecap={"round"} className={"glow-blur"}></rect>
			</svg>
		</Link>,
		<div key={"external-links"}
				 className={"external-nav-link-wrapper"}
				 style={(className && externalLinksStyle)}>
			<a
				 href={"https://www.linkedin.com/in/steven-dao-294910126/"}
				 target={"_blank"}
				 rel={"noreferrer"}
				 className={`nav-link external-nav-link ${className}`}>
				<FontAwesomeIcon icon={faLinkedinIn}
												 size={"1x"}
												 className={"layer-1"} />
			</a>
			<a key={"github-link"}
				 href={"https://github.com/sddao17?tab=repositories"}
				 target={"_blank"}
				 rel={"noreferrer"}
				 className={`nav-link external-nav-link ${className}`}>
				<FontAwesomeIcon icon={faGithub}
												 size={"1x"}
												 className={"layer-1"}/>
			</a>
		</div>,
		<SwitchTheme key={"switch-theme"}
								 style={(className && switchThemeStyle)}
								 className={`${className} switch-theme`} />
	];

	return (links);
}
