
import {motion} from "framer-motion";
import {getTitleBounceAnimation} from "../../utilities/AnimationVariants";

export default function AnimatedWord(props) {
	const word = props.word;
	const className = props.className;
	const animatedLetters = [];
	let index = 0;
	let delayDuration = 0.3;

	// Animating individual letters
	for (let i = 0; i < word.length; ++i) {
		animatedLetters.push(
				<motion.span key={index++}
										 className={className}
										 whileInView={"visible"}
										 view={{once: true}}
										 animate={getTitleBounceAnimation(delayDuration)}>
					{word[i]}
				</motion.span>
		);
		delayDuration += 0.1;
	}

	return (
			<span>
				{animatedLetters}
			</span>
	);
}
