
export default function TetrisBlockSVGI() {
	return (
			<svg id="tetris-block-i"
					 width="292" height="82" viewBox="0 0 292 82" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M211 1H291V81H211V1Z" fill="black"/>
				<path d="M221 11H281V71H221V11Z" fill="#00CED1"/>
				<path d="M231 11H281V61H231V11Z" fill="#00FFFF"/>
				<path d="M141 1H221V81H141V1Z" fill="black"/>
				<path d="M151 11H211V71H151V11Z" fill="#00CED1"/>
				<path d="M161 11H211V61H161V11Z" fill="#00FFFF"/>
				<path d="M71 1H151V81H71V1Z" fill="black"/>
				<path d="M81 11H141V71H81V11Z" fill="#00CED1"/>
				<path d="M91 11H141V61H91V11Z" fill="#00FFFF"/>
				<path d="M1 1H81V81H1V1Z" fill="black"/>
				<path d="M11 11H71V71H11V11Z" fill="#00CED1"/>
				<path d="M21 11H71V61H21V11Z" fill="#00FFFF"/>
				{/*<path d="M211 0.5H210.5H151.5H151H141H140.5H81.5H81H71H70.5H1H0.5V1V81V81.5H1H70.5H71H81H81.5H140.5H141H151H151.5H210.5H211H221H221.5H291H291.5V81V1V0.5H291H221.5H221H211Z" stroke="black"/>*/}
			</svg>
	);
}
